import styled from "styled-components";
import { FormButton } from "./FormButton";

const Controls = styled.div`
  display: flex;
  margin-top: auto;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    margin-top: 10px;
  }
`;

interface FormControlsProps {
  children?: React.ReactNode;
  disabled?: boolean;
  onNext: () => void;
}

export const FormControls = ({
  children,
  onNext,
  disabled,
}: FormControlsProps) => (
  <Controls>
    <FormButton onClick={onNext} disabled={disabled}>
      {children || "Next Step"}
    </FormButton>
  </Controls>
);
