import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FormButton } from "../../components/FormButton";
import { TextInput } from "../../components/TextInput";
import Store from "../../img/Store";
import Truck from "../../img/Truck";
import { Order } from "../../types";
import { useOrder, useProductSchemas } from "./OrderForm";
import { getOrderJson } from "./productHooks";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 1em;
  gap: 1em;
  padding-bottom: 14em;

  @media (min-width: 600px) {
    padding-bottom: 2em;
  }
`;
const DueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  @media (min-width: 600px) {
    flex-direction: row;

    & > div {
      flex-basis: 50%;
    }
  }
`;
const Instructions = styled.p`
  margin: 0px;
`;
const PickerWrapper = styled.div`
  display: flex;
  gap: 1em;
`;
const PickerButton = styled.button`
  border: 2px solid rgba(255, 255, 255, 0.4);
  border-radius: 0.75em;
  background: none;
  font-size: 1em;
  font-weight: 600;
  display: flex;
  flex-basis: 50%;
  padding: 0.5em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    fill: var(--silver);
    max-width: 100px;
  }

  span {
    font-size: 1.4em;
  }

  &[data-selected="true"] {
    background: var(--mint);
    border-color: var(--mint);
    color: var(--blue);

    svg {
      fill: var(--blue);
    }
  }
`;
const Label = styled.label`
  font-size: 0.8em;
  font-weight: 600;
  margin-bottom: -0.5em;
  margin-top: 0.25em;
  display: flex;
`;
const Disclaimer = styled.p`
  margin: 0px;
  font-size: 0.85em;
`;
const Controls = styled.div`
  flex-direction: column;
  justify-content: center;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  padding: 1em;
  z-index: 9;
  width: 100%;

  &::before {
    content: "";
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: calc(100% + 5em);
    background: linear-gradient(
      to top,
      var(--blue) 0%,
      var(--blue) 60%,
      var(--blue-transparent)
    );
    pointer-events: none;
    z-index: -1;
  }

  @media (min-width: 600px) {
    position: relative;
    justify-content: center;
    padding: 0px;
    padding-top: 1em;

    &::before {
      display: none;
    }
  }
`;
const ControlColumn = styled.div`
  gap: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 570px;

  @media (min-width: 600px) {
    flex-direction: column-reverse;
  }
`;

export const getDeliveryStepIsValid = (order: Order) => {
  if (
    order.dueDate.length === 10 &&
    order.dueTime.length === 5 &&
    order.deliveryMethod
  ) {
    if (order.deliveryMethod === "delivery") {
      return order.deliveryAddress && order.deliveryCity;
    }

    return true;
  }
  return false;
};

export const Delivery = () => {
  const { order, setOrder } = useOrder();
  const { products } = useProductSchemas();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [didError, setDidError] = React.useState(false);
  const navigate = useNavigate();

  const mayProceed = React.useMemo(() => {
    return getDeliveryStepIsValid(order);
  }, [order]);

  const handleSubmit = async () => {
    if (mayProceed && products) {
      setDidError(false);
      setIsSubmitting(true);
      const jsonOrder = getOrderJson(order, products);

      try {
        await axios.post(`/.netlify/functions/submit-order`, jsonOrder);
        navigate("/success", { replace: true });
      } catch (err) {
        console.error(err);
        setDidError(true);
      }
    }
  };

  return (
    <Wrapper>
      <Instructions>
        Our treats are made to order. Let us know when you need them by.
      </Instructions>
      <DueWrapper>
        <TextInput
          type="date"
          label="Due Date"
          value={order.dueDate}
          onChange={(dueDate) => {
            setOrder?.((x) => ({ ...x, dueDate }));
          }}
        />
        <TextInput
          type="time"
          label="What Time?"
          value={order.dueTime}
          onChange={(dueTime) => {
            setOrder?.((x) => ({ ...x, dueTime }));
          }}
          min="09:00"
          max="17:00"
        />
      </DueWrapper>
      <div style={{ fontSize: 24 }}>
        <Label>Delivery Method</Label>
      </div>
      <PickerWrapper>
        <PickerButton
          data-selected={order.deliveryMethod === "pickup"}
          onClick={() =>
            setOrder?.((x) => ({ ...x, deliveryMethod: "pickup" }))
          }
        >
          <Store />
          <span>Pickup</span>
        </PickerButton>
        <PickerButton
          data-selected={order.deliveryMethod === "delivery"}
          onClick={() =>
            setOrder?.((x) => ({ ...x, deliveryMethod: "delivery" }))
          }
        >
          <Truck />
          <span>Delivery</span>
        </PickerButton>
      </PickerWrapper>
      {order.deliveryMethod === "delivery" && (
        <>
          <Instructions>
            We deliver to Fargo, West Fargo, Moorhead, and Horace. If you live
            elsewhere please pick up your order instead.
          </Instructions>
          <TextInput
            label="Delivery Street Address"
            value={order.deliveryAddress}
            onChange={(deliveryAddress) =>
              setOrder?.((x) => ({ ...x, deliveryAddress }))
            }
            name="street"
          />
          <div style={{ display: "flex", gap: "1em" }}>
            <div>
              <TextInput
                label="City"
                value={order.deliveryCity}
                onChange={(deliveryCity) =>
                  setOrder?.((x) => ({ ...x, deliveryCity }))
                }
                name="city"
              />
            </div>
            <div style={{ flexBasis: "30%" }}>
              <TextInput
                label="State"
                value="ND"
                onChange={() => {}}
                name="state"
                disabled
              />
            </div>
          </div>
        </>
      )}
      {mayProceed && (
        <Controls>
          <ControlColumn>
            <FormButton
              style={{
                marginTop: 0,
                background: didError ? "var(--red)" : "",
                color: didError ? "var(--silver)" : "",
              }}
              onClick={handleSubmit}
            >
              {didError
                ? "Something Went Wrong"
                : isSubmitting
                ? "Submitting..."
                : "Submit"}
            </FormButton>
            {didError ? (
              <Disclaimer>
                Sorry, looks like something went wrong while trying to submit
                your order. Please try again. If you're still having trouble
                please email{" "}
                <a
                  style={{ color: "var(--silver)" }}
                  href="mailto:hello@thesugarmillshop.com"
                >
                  hello@thesugarmillshop.com
                </a>
              </Disclaimer>
            ) : (
              <Disclaimer>
                Note: Our availability is limited and submitting this request{" "}
                <strong>does not guarantee</strong> your order until after we
                have confirmed it. You will receive notification within 2-3
                business days if we are able to take on your order.
              </Disclaimer>
            )}
          </ControlColumn>
        </Controls>
      )}
    </Wrapper>
  );
};
