import { nanoid } from "nanoid/non-secure";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FormButton } from "../../components/FormButton";
import { StepSchema, StepsValue, UploadStatus } from "../../types";
import { useCurrentStep } from "./AddProduct";
import { CakeSizePicker } from "./CakeSizePicker";
import { Decoration } from "./Decoration";
import { FlavorPicker } from "./FlavorPicker";
import { useOrder } from "./OrderForm";
import { useCurrentProduct, useExistingProduct } from "./productHooks";
import { Quantity } from "./Quantity";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 6em;
`;
const Controls = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  bottom: 0px;
  width: 100%;
  padding: 1em;
  background: linear-gradient(to top, var(--blue), var(--blue-transparent));
  z-index: 9;

  @media (min-width: 600px) {
    position: relative;
    padding-top: 2em;
  }
`;

export const AddProductSteps = () => {
  const { order, setOrder } = useOrder();
  const { productSchema } = useCurrentProduct();
  const [productValue, setProductValue] = React.useState<
    StepsValue | undefined
  >();
  const { currentStep, nextStep } = useCurrentStep();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (productSchema) {
      const emptySchema = productSchema.orderFields.reduce<StepsValue>(
        (acc, field) => {
          if (field._type === "cakeSize") {
            acc.cakeSize = {
              heightId: undefined,
              diameterId: undefined,
            };
          } else if (field._type === "flavors") {
            acc.flavors = {
              selected: "",
              customFlavor: "",
            };
          } else if (field._type === "quantity") {
            acc.quantity = 12;
          } else if (field._type === "decoration") {
            acc.decoration = {
              instructions: "",
              photos: [],
            };
          }
          return acc;
        },
        {}
      );

      setProductValue(emptySchema);
    }
  }, [productSchema]);

  if (!productSchema || !currentStep || !productValue || !setOrder) return null;

  const addProduct = () => {
    setOrder({
      ...order,
      products: [
        ...order.products,
        {
          id: nanoid(8),
          productId: productSchema?._id,
          value: productValue,
        },
      ],
    });

    navigate("/order/products");
  };

  return (
    <ProductSteps
      value={productValue}
      currentStep={currentStep}
      nextStep={nextStep}
      onChange={(value) => setProductValue(value)}
      onCommitStep={addProduct}
      allSteps={productSchema.orderFields}
    />
  );
};

export const EditProductSteps = () => {
  const { productSchema } = useCurrentProduct();
  const { product, setProduct } = useExistingProduct();
  const { currentStep, nextStep } = useCurrentStep();
  const navigate = useNavigate();

  if (!productSchema || !currentStep || !product?.value) return null;

  return (
    <ProductSteps
      value={product?.value}
      currentStep={currentStep}
      nextStep={nextStep}
      onChange={(value) => setProduct({ ...product, value })}
      onCommitStep={() => navigate(`/order/products`)}
      allSteps={productSchema.orderFields}
    />
  );
};

interface ProductStepsProps {
  currentStep: StepSchema;
  nextStep: StepSchema | undefined;
  allSteps: StepSchema[];
  value: StepsValue;
  onChange: (value: StepsValue) => void;
  onCommitStep: () => void;
}

const getMayProceed: {
  [stepId: string]: (value: StepsValue | undefined) => boolean;
} = {
  cakeSize: (value) =>
    (!!value?.cakeSize?.heightId && !!value?.cakeSize?.diameterId) ?? false,
  quantity: (value) => !!value?.quantity ?? false,
  flavors: (value) =>
    (!!value?.flavors?.selected || !!value?.flavors?.customFlavor) ?? false,
  decoration: (value) =>
    value?.decoration?.photos.every(
      (photo) => photo.status !== UploadStatus.Uploading
    ) ?? true,
};

export const ProductSteps = ({
  currentStep,
  nextStep,
  value,
  allSteps,
  onChange,
  onCommitStep,
}: ProductStepsProps) => {
  const navigate = useNavigate();

  const renderStep = () => {
    if (currentStep._type === "cakeSize" && value.cakeSize) {
      return (
        <CakeSizePicker
          value={value.cakeSize}
          onChange={(cakeSize) => onChange({ ...value, cakeSize })}
          heightOptions={currentStep.heights}
        />
      );
    }

    if (currentStep._type === "quantity" && value.quantity !== undefined) {
      return (
        <Quantity
          value={value.quantity}
          onChange={(quantity) => onChange({ ...value, quantity })}
          min={currentStep.min}
          max={currentStep.max}
          interval={currentStep.interval}
        />
      );
    }

    if (currentStep._type === "flavors" && value.flavors) {
      return (
        <FlavorPicker
          value={value.flavors}
          flavors={currentStep.flavors}
          onChange={(flavors) => onChange({ ...value, flavors })}
        />
      );
    }

    if (currentStep._type === "decoration" && value.decoration !== undefined) {
      return (
        <Decoration
          value={value.decoration}
          onChange={(decoration) => onChange({ ...value, decoration })}
        />
      );
    }

    return null;
  };

  const mayProceed = getMayProceed[currentStep._type](value);

  const handleProceed = () => {
    if (mayProceed) {
      if (nextStep) {
        navigate(`../${nextStep?._key}`);
      } else {
        onCommitStep();
      }
    }
  };

  React.useEffect(() => {
    const currentStepIndex = allSteps.findIndex(
      (x) => x._key === currentStep._key
    );
    if (currentStepIndex >= 0) {
      const previousStepsAreValid = allSteps
        .slice(0, currentStepIndex)
        .every((x) => getMayProceed[x._type](value));
      if (!previousStepsAreValid) {
        navigate(`../${allSteps[0]._key}`, { replace: true });
      }
    }
  }, [currentStep, allSteps, value, navigate]);

  return (
    <Wrapper>
      {renderStep()}
      {mayProceed && (
        <Controls>
          <FormButton
            disabled={!mayProceed}
            onClick={handleProceed}
            style={{ marginTop: 0 }}
          >
            Next Step
          </FormButton>
        </Controls>
      )}
    </Wrapper>
  );
};
