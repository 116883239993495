import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import styled from "styled-components";
import MultiIcon from "../../img/CakeMulti";
import StandardIcon from "../../img/CakeStandard";
import TallIcon from "../../img/CakeTall";
import { CakeDiameter, CakeHeight, SanityKeyed } from "../../schema";
import { CakeSize as CakeSizeValue } from "../../types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const PickerEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const PickerButtonWrapper = styled.button`
  background: var(--silver);
  color: var(--blue);
  width: 100%;
  border: none;
  border-radius: 0.25em;
  font-size: 24px;
  padding: 0px;
  box-shadow: 0.35em 0.35em 0px rgba(0, 0, 0, 0.2);
  transition: transform 100ms;
  will-change: transform;
  cursor: pointer;

  &[data-selected="true"] {
    background: var(--pink);
    color: var(--silver);
    transform: scale(0.96);

    & svg *[stroke="#ffa4c9"] {
      stroke: var(--pink);
    }
  }

  &[data-ghost="true"] {
    opacity: 0.6;
  }
`;
const PickerButtonInnerWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const IconWrapper = styled.div`
  max-width: min(20vw, 100px);
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 100%;
    & *[stroke="#ffa4c9"] {
      stroke-width: max(min(20px, 7vw), 30px);
      stroke: var(--blue);
    }
  }
`;
const PickerLabel = styled.span`
  font-weight: bold;
`;
const DiameterOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 0em 0em 2em;
  gap: 1em;
`;
const DiameterInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4em 0.5em;
  gap: 0.5em;
`;
const Inches = styled.span`
  display: inline-flex;
  gap: 0.25em;
`;
const Inch = styled.span``;
const Servings = styled.span`
  font-size: 0.65em;
  padding-top: 0.4em;
  font-weight: 400;
`;
const ParentPrice = styled.span`
  margin-left: auto;
  font-weight: 600;
  padding-right: 0.5em;
`;
const Price = styled.span`
  margin-left: auto;
`;
// const Cents = styled.span`
//   font-size: 0.75em;
// `;
const InchUnit = styled.span`
  font-size: 0.75em;
  padding-left: 0.1em;
`;
const DiameterOption = styled.button`
  border: none;
  background: var(--silver);
  color: var(--blue);
  border-radius: 0.25em;
  font-size: 24px;
  font-weight: 600;
  padding: 0px;
  box-shadow: 0.35em 0.35em 0px rgba(0, 0, 0, 0.2);
  transition: transform 100ms;
  cursor: pointer;

  &[data-selected="true"] {
    background: var(--pink);
    color: var(--silver);
    transform: scale(0.95);
  }

  &[data-ghost="true"] {
    opacity: 0.6;
  }
`;
const Hint = styled.p`
  margin: 0px;
`;

const ICONS = {
  standard: <StandardIcon />,
  tall: <TallIcon />,
  multi: <MultiIcon />,
};

export const CakeSizePicker = ({
  value,
  onChange,
  heightOptions,
}: {
  value: CakeSizeValue;
  onChange: (value: CakeSizeValue) => void;
  heightOptions: SanityKeyed<CakeHeight>[];
}) => {
  const handleHeightChange = (heightId: string) => () => {
    if (heightId !== value.heightId) {
      onChange({ ...value, heightId, diameterId: undefined });
    }
  };

  return (
    <Wrapper>
      {heightOptions.map((opt) => (
        <PickerButton
          icon={ICONS[opt.icon]}
          text={opt.name}
          isSelected={value.heightId === opt._key}
          anySelected={!!value.heightId}
          onClick={handleHeightChange(opt._key)}
          diameters={opt.diameters}
          selectedDiameter={value.diameterId}
          onDiameterSelected={(diameterId) =>
            onChange({ ...value, diameterId })
          }
          key={opt._key}
        />
      ))}
      <Hint>
        Please note that prices are an estimate <strong>only</strong>, and may
        change depending on availability and decorative needs. A final price
        will be emailed to you when confirming your order.
      </Hint>
    </Wrapper>
  );
};

const PickerButton = ({
  icon,
  text,
  isSelected,
  anySelected,
  onClick,
  diameters,
  selectedDiameter,
  onDiameterSelected,
}: {
  icon: React.ReactNode;
  text: string;
  isSelected: boolean;
  onClick: React.MouseEventHandler;
  anySelected: boolean;
  diameters: SanityKeyed<CakeDiameter>[];
  selectedDiameter: string | undefined;
  onDiameterSelected: (diameterId: string) => void;
}) => (
  <PickerEntryWrapper>
    <PickerButtonWrapper
      data-selected={isSelected}
      data-ghost={anySelected && !isSelected}
      onClick={onClick}
    >
      <PickerButtonInnerWrapper>
        <IconWrapper>{icon}</IconWrapper>
        <PickerLabel>{text}</PickerLabel>
        {!isSelected && <ParentPrice>${diameters[0].price}+</ParentPrice>}
      </PickerButtonInnerWrapper>
    </PickerButtonWrapper>
    <AnimatePresence>
      {isSelected && (
        <motion.div
          variants={{
            initial: {
              height: 0,
            },
            animate: {
              height: "auto",
              transition: {
                when: "beforeChildren",
              },
            },
            exit: {
              height: 0,
              transition: {
                when: "afterChildren",
              },
            },
          }}
          initial="initial"
          animate="animate"
          exit="exit"
          className="picker-item-inner-wrapper"
        >
          <motion.div
            variants={{
              initial: {
                opacity: 0,
              },
              animate: {
                opacity: 1,
              },
              exit: {
                opacity: 0,
              },
            }}
          >
            <DiameterOptionsWrapper>
              {diameters.map((diameter) => {
                const inches = diameter.inches.split(", ");

                return (
                  <DiameterOption
                    data-selected={diameter._key === selectedDiameter}
                    data-ghost={
                      !!selectedDiameter && diameter._key !== selectedDiameter
                    }
                    onClick={() => onDiameterSelected(diameter._key)}
                    key={diameter._key}
                  >
                    <DiameterInnerWrapper>
                      <Inches
                        style={{
                          fontSize: `${1 - (inches.length - 1) * 0.1}em`,
                        }}
                      >
                        {inches.map((inch) => (
                          <Inch>
                            {inch}
                            <InchUnit>in</InchUnit>
                          </Inch>
                        ))}
                      </Inches>
                      <Servings>{diameter.servingHint}</Servings>
                      <Price>
                        ${diameter.price}
                        {/* <Cents>
                          {diameter.price.toFixed(2).split(".").pop()}
                        </Cents> */}
                      </Price>
                    </DiameterInnerWrapper>
                  </DiameterOption>
                );
              })}
            </DiameterOptionsWrapper>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  </PickerEntryWrapper>
);
