function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
      <path fill="none" d="M0 0H1000V1000H0z"></path>
      <ellipse
        cx="500"
        cy="548.7"
        fill="#eaf5f4"
        stroke="#ffa4c9"
        strokeMiterlimit="10"
        strokeWidth="20"
        rx="255.87"
        ry="98.57"
      ></ellipse>
      <path
        fill="#eaf5f4"
        stroke="#ffa4c9"
        strokeMiterlimit="10"
        strokeWidth="20"
        d="M755.87 548.7v277.58c0 54.45-114.55 98.57-255.87 98.57s-255.87-44.11-255.87-98.57V548.7c0 54.43 114.55 98.57 255.87 98.57s255.87-44.14 255.87-98.57z"
      ></path>
      <path
        fill="#eaf5f4"
        stroke="#ffa4c9"
        strokeMiterlimit="10"
        strokeWidth="20"
        d="M691.27 311.41v229c0 31.34-85.63 56.74-191.27 56.74s-191.27-25.39-191.27-56.74v-229c0 31.33 85.63 56.74 191.27 56.74s191.27-25.41 191.27-56.74z"
      ></path>
      <ellipse
        cx="500"
        cy="311.41"
        fill="#eaf5f4"
        stroke="#ffa4c9"
        strokeMiterlimit="10"
        strokeWidth="20"
        rx="191.27"
        ry="56.74"
      ></ellipse>
      <ellipse
        cx="500"
        cy="116.88"
        fill="#eaf5f4"
        stroke="#ffa4c9"
        strokeMiterlimit="10"
        strokeWidth="20"
        rx="140.68"
        ry="41.73"
      ></ellipse>
      <path
        fill="#eaf5f4"
        stroke="#ffa4c9"
        strokeMiterlimit="10"
        strokeWidth="20"
        d="M640.68 116.88v168.43c0 23.05-62.98 41.73-140.68 41.73s-140.68-18.68-140.68-41.73V116.88c0 23.04 62.98 41.73 140.68 41.73s140.68-18.69 140.68-41.73z"
      ></path>
    </svg>
  );
}

export default Icon;
