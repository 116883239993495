import { nanoid } from "nanoid/non-secure";
import React, { SVGAttributes } from "react";
import styled from "styled-components";
import Email from "../../img/Email";
import Instagram from "../../img/Instagram";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex: 1;
  max-width: 600px;
  width: 100%;

  @media (min-width: 600px) {
    padding-top: 50px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 1em;
  gap: 1em;
`;
const Title = styled.h1`
  margin: 0px;
  font-size: 4em;
  font-family: var(--font-primary);
  font-weight: 400;
  text-align: center;
  font-style: italic;
  width: 100%;
  text-shadow: 0.1em 0.1em 0px var(--red);
  color: var(--yellow);
`;
const Description = styled.p`
  margin: 0px;
  text-align: center;
  margin-top: 0.5em;
  font-size: 1.4em;

  strong {
    text-decoration: underline;
  }
`;
const CTAButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  background: var(--pink);
  border-radius: 0.5em;
  border: none;
  box-shadow: 0.5em 0.5em 0px rgba(0, 0, 0, 0.2);
  color: var(--blue);
  font-weight: 600;
  font-size: 1.5em;
  padding: 0.5em 1em 0.5em 0.5em;
  width: 100%;
  max-width: 400px;
  margin-top: 0.25em;
  text-decoration: none;

  svg {
    fill: var(--blue);
    width: 1.5em;
    height: 1.5em;
  }
`;

const ButtonText = styled.div`
  width: 100%;
  text-align: left;
`;

export const Success = () => {
  const screen = useWindowSize();
  const titleRef = React.useRef<HTMLHeadingElement>(null);
  const [titleCenter, setTitleCenter] = React.useState(1);
  const [renderKey, setRenderKey] = React.useState("initial");

  React.useEffect(() => {
    const rect = titleRef.current?.getBoundingClientRect();
    if (!rect) return;

    setTitleCenter(rect.y - rect.height / 2);
  }, [screen]);

  React.useEffect(() => {
    const handleFocus = () => {
      setRenderKey(nanoid(8));
    };

    window.addEventListener("visibilitychange", handleFocus);

    return () => {
      window.removeEventListener("visibilitychange", handleFocus);
    };
  }, []);

  return (
    <PageWrapper>
      <Wrapper>
        <Title ref={titleRef}>Hurray!</Title>
        <Description>Your order has been submitted!</Description>
        <Description style={{ marginBottom: ".5em" }}>
          Remember, our availability is limited and submitting this request{" "}
          <strong>does not guarantee</strong> your order until after we have
          confirmed it. You will receive notification within 2-3 business days
          if we are able to take on your order.
        </Description>
        <CTAButton
          href="http://instagram.com/_u/thesugarmillco/"
          target="_blank"
        >
          <Instagram />
          <ButtonText>Follow us on Instagram</ButtonText>
        </CTAButton>
        <CTAButton
          href="mailto:hello@thesugarmillshop.com"
          target="_blank"
          style={{ background: "var(--silver)" }}
        >
          <Email />
          <ButtonText>Email with questions</ButtonText>
        </CTAButton>
        <ParticleBackground
          screen={screen}
          titleCenter={titleCenter}
          key={renderKey}
        />
      </Wrapper>
    </PageWrapper>
  );
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  React.useEffect(() => {
    const measure = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", measure);

    return () => {
      window.removeEventListener("resize", measure);
    };
  }, []);

  return windowSize;
};

const ParticleWrapper = styled.div`
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;

  g,
  line {
    will-change: transform;
    transform-origin: center;
    transform-box: fill-box;
  }
`;
const ParticleStage = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
`;

interface ParticleBackgroundProps {
  screen: { width: number; height: number };
  titleCenter: number;
}

const remap = (
  value: number,
  low1: number,
  high1: number,
  low2: number,
  high2: number
) => {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
};

const ParticleBackground = ({
  screen,
  titleCenter,
}: ParticleBackgroundProps) => {
  const aspectRatio = screen.height / screen.width;
  const stageWidth = 300;
  const stageHeight = stageWidth * aspectRatio;
  const numPills = Math.max(Math.floor(screen.width / 55), 15);

  return (
    <ParticleWrapper>
      <ParticleStage
        viewBox={`0 0 ${stageWidth} ${stageHeight}`}
        key={`${stageWidth}_${stageHeight}`}
      >
        <g
          transform={`translate(0 ${remap(
            titleCenter,
            0,
            screen.height,
            0,
            stageHeight
          )})`}
        >
          {new Array(numPills).fill(null).map((_, i) => (
            <Pill
              minX2={0}
              maxX2={stageWidth}
              endY={stageHeight}
              strokeWidth={stageHeight / 80}
              strokeMiterlimit={10}
              strokeLinecap={"round"}
              key={i}
            />
          ))}
        </g>
      </ParticleStage>
    </ParticleWrapper>
  );
};

interface PillProps extends SVGAttributes<SVGLineElement> {
  minX2: number;
  maxX2: number;
  endY: number;
}

const COLORS = ["pink", "mint", "yellow", "red"];

const getHeightByTime = (time: number, maxHeight: number) =>
  Math.pow(2 * time - 1, 2) * -1 + maxHeight;

const getRandomBetween = (min: number, max: number) =>
  Math.random() * (max - min) + min;

const getRandomFromArray = <T extends any>(arr: T[]) =>
  arr[Math.floor(Math.random() * arr.length)];

const Pill = ({ minX2, maxX2, endY, ...props }: PillProps) => {
  const wrapperRef = React.useRef<SVGGElement>(null);
  const lineRef = React.useRef<SVGLineElement>(null);
  const length = React.useMemo(
    () => getRandomBetween(endY / 50, endY / 20),
    [endY]
  );
  const color = React.useRef(getRandomFromArray(COLORS));
  const isRunning = React.useRef(false);
  const duration = 1500;
  const startTime = React.useRef(0);
  const frame = React.useRef(0);
  const stageCenter = maxX2 / 2 - length / 2;
  const targetX = React.useRef(
    getRandomBetween(stageCenter - maxX2 / 2, stageCenter + maxX2 / 2)
  );
  const maxHeight = React.useRef(getRandomBetween(0.5, 1));
  const initialAngle = React.useRef(getRandomBetween(-360, 360));
  const targetAngle = React.useRef(getRandomBetween(-720, 720));
  const initialDelay = React.useRef(getRandomBetween(0, 1500));

  React.useEffect(() => {
    const draw = (delta: number) => {
      if (!isRunning.current) return;

      if (startTime.current === 0) {
        startTime.current = delta;
      }

      frame.current = delta - startTime.current;
      const progress = frame.current / duration;

      if (progress > 1) {
        frame.current = 0;
        startTime.current = 0;
        targetX.current = getRandomBetween(
          stageCenter - maxX2 / 2,
          stageCenter + maxX2 / 2
        );
        initialAngle.current = getRandomBetween(-360, 360);
        targetAngle.current = getRandomBetween(-720, 720);
        color.current = getRandomFromArray(COLORS);
        lineRef.current?.setAttribute("stroke", `var(--${color.current})`);
        maxHeight.current = getRandomBetween(0.5, 1);
      }

      if (wrapperRef.current) {
        wrapperRef.current.setAttribute(
          "transform",
          `translate(${remap(
            progress,
            0,
            1,
            stageCenter,
            targetX.current
          )} ${remap(
            getHeightByTime(progress, maxHeight.current),
            0,
            1,
            endY,
            0
          )})
          `
        );
        lineRef.current?.setAttribute(
          "transform",
          `rotate(${remap(
            progress,
            0,
            1,
            initialAngle.current,
            targetAngle.current
          )} 0 0)`
        );
      }

      requestAnimationFrame(draw);
    };

    let runner = 0;

    const initiate = () => {
      isRunning.current = true;
      lineRef.current?.setAttribute("opacity", "1");
      runner = requestAnimationFrame(draw);
    };

    const timeoutRunner = setTimeout(initiate, initialDelay.current);

    return () => {
      isRunning.current = false;
      cancelAnimationFrame(runner);
      clearTimeout(timeoutRunner);
    };
  }, [endY, stageCenter, duration, targetX, maxX2]);

  return (
    <g
      transform={`translate(${maxX2 / 2} 0) rotate(${getRandomBetween(
        0,
        360
      )} 0 0)`}
      ref={wrapperRef}
    >
      <line
        ref={lineRef}
        x1={0}
        x2={length}
        y1={0}
        y2={0}
        stroke={`var(--${color.current})`}
        transform-origin="center"
        opacity={0}
        {...props}
      />
    </g>
  );
};
