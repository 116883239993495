function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
      <path d="M763.48 160.16c-7.81.12-15.26 3.34-20.71 8.94a29.538 29.538 0 00-8.34 20.97v59.02h-59.02a29.52 29.52 0 00-29.93 29.51c0 7.9 3.17 15.47 8.79 21.01a29.504 29.504 0 0021.14 8.5h59.02v59.02a29.52 29.52 0 0029.51 29.93c7.9 0 15.47-3.17 21.02-8.79 5.55-5.62 8.6-13.24 8.49-21.14v-59.02h59.02a29.52 29.52 0 0029.93-29.51c0-7.9-3.17-15.47-8.79-21.01s-13.24-8.61-21.14-8.5h-59.02v-59.02c.11-7.98-3.01-15.66-8.66-21.29a29.538 29.538 0 00-21.31-8.62z"></path>
      <path
        fillRule="evenodd"
        d="M705.53 780.53c0 32.76-26.56 59.31-59.31 59.31s-59.31-26.56-59.31-59.31 26.56-59.31 59.31-59.31 59.31 26.56 59.31 59.31M498.39 780.53c0 32.76-26.56 59.31-59.31 59.31s-59.31-26.56-59.31-59.31 26.56-59.31 59.31-59.31 59.31 26.56 59.31 59.31"
      ></path>
      <path d="M85.28 160.56a29.52 29.52 0 00-29.93 29.51c0 7.9 3.17 15.47 8.79 21.01a29.504 29.504 0 0021.14 8.5h74.63c13.01 0 24.3 8.27 28.18 20.69 3.05 9.76 9.41 30.15 13.26 42.48.45 3.13 1.4 6.16 2.82 8.99 24.14 77.25 53.52 171.3 80.16 256.58.45 3.15 1.4 6.2 2.82 9.05 7.3 23.38 17.41 55.62 22.59 72.21 11.52 36.86 45.91 62.13 84.49 62.13h340.2a29.52 29.52 0 0029.93-29.52c0-7.91-3.17-15.47-8.79-21.01a29.504 29.504 0 00-21.14-8.5H394.24c-13.01 0-24.3-8.27-28.18-20.69-2.75-8.81-8.58-27.41-11.99-38.33h373.87c38.58 0 72.97-25.27 84.49-62.13h.05c5.07-16.24 9.68-31.06 9.68-31.06a29.525 29.525 0 00-4.27-26.64 29.513 29.513 0 00-24.15-12.03c-6.29.08-12.39 2.17-17.41 5.97-5.02 3.79-8.69 9.1-10.49 15.12 0 0-4.66 14.79-9.74 31.06-3.88 12.42-15.18 20.69-28.18 20.69H335.64c-22.36-71.53-42.85-137.12-64.55-206.55h286.32c7.9.12 15.51-2.94 21.14-8.49 5.62-5.55 8.79-13.11 8.79-21.01s-3.17-15.47-8.79-21.02a29.495 29.495 0 00-21.14-8.49H252.65c-2.22-7.1-6.35-20.33-8.24-26.39-11.52-36.86-45.91-62.13-84.49-62.13H85.28z"></path>
    </svg>
  );
}

export default Icon;
