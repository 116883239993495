import "normalize.css";
import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import "./index.css";
import { App } from "./pages/App/App";
import Home from "./pages/Home/Home";
import { AddProduct } from "./pages/Order/AddProduct";
import { ContactInfoForm } from "./pages/Order/ContactInfoForm";
import { Delivery } from "./pages/Order/Delivery";
import { EditProduct } from "./pages/Order/EditProduct";
import { OrderForm } from "./pages/Order/OrderForm";
import { PickProduct } from "./pages/Order/PickProduct";
import { ProductsCart } from "./pages/Order/ProductsCart";
import { AddProductSteps, EditProductSteps } from "./pages/Order/ProductSteps";
import { Success } from "./pages/Order/Success";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="order" element={<OrderForm />}>
            <Route index element={<Navigate to="contact" replace />} />
            <Route path="contact" element={<ContactInfoForm />} />
            <Route path="products" element={<Outlet />}>
              <Route index element={<ProductsCart />} />
              <Route path="add" element={<Outlet />}>
                <Route index element={<PickProduct />} />
                <Route path=":productId" element={<AddProduct />}>
                  <Route path=":stepId" element={<AddProductSteps />} />
                </Route>
              </Route>
              <Route path="edit" element={<Outlet />}>
                <Route path=":productId" element={<EditProduct />}>
                  <Route path=":orderProductId" element={<Outlet />}>
                    <Route path=":stepId" element={<EditProductSteps />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="delivery" element={<Delivery />} />
          </Route>
          <Route path="success" element={<Success />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
