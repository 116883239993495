function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
      <path
        fill="var(--blue)"
        d="M500.15 102.15c-104.35 0-117.44.44-158.42 2.31-40.9 1.87-68.83 8.36-93.27 17.86-25.27 9.82-46.69 22.96-68.06 44.32-21.36 21.36-34.5 42.79-44.32 68.06-9.5 24.44-15.99 52.37-17.86 93.27-1.87 40.98-2.31 54.07-2.31 158.42s.44 117.44 2.31 158.42c1.87 40.9 8.36 68.83 17.86 93.27 9.82 25.27 22.96 46.69 44.32 68.06 21.36 21.36 42.79 34.5 68.06 44.32 24.44 9.5 52.37 15.99 93.27 17.86 40.98 1.87 54.07 2.31 158.42 2.31s117.44-.44 158.42-2.31c40.9-1.87 68.83-8.36 93.27-17.86 25.27-9.82 46.69-22.96 68.06-44.32 21.36-21.36 34.5-42.79 44.32-68.06 9.5-24.44 15.99-52.37 17.86-93.27 1.87-40.98 2.31-54.07 2.31-158.42s-.44-117.44-2.31-158.42c-1.87-40.9-8.36-68.83-17.86-93.27-9.82-25.27-22.96-46.7-44.32-68.06s-42.79-34.5-68.06-44.32c-24.44-9.5-52.37-15.99-93.27-17.86-40.98-1.87-54.07-2.31-158.42-2.31zm0 69.23c102.59 0 114.75.39 155.26 2.24 37.46 1.71 57.81 7.97 71.35 13.23 17.94 6.97 30.74 15.3 44.18 28.74 13.45 13.45 21.77 26.25 28.74 44.18 5.26 13.54 11.52 33.89 13.23 71.35 1.85 40.52 2.24 52.67 2.24 155.26s-.39 114.75-2.24 155.26c-1.71 37.46-7.97 57.81-13.23 71.35-6.97 17.94-15.3 30.74-28.74 44.18-13.44 13.45-26.24 21.77-44.18 28.74-13.54 5.26-33.89 11.52-71.35 13.23-40.51 1.85-52.66 2.24-155.26 2.24s-114.75-.39-155.26-2.24c-37.46-1.71-57.81-7.97-71.35-13.23-17.94-6.97-30.74-15.3-44.18-28.74-13.45-13.44-21.77-26.24-28.74-44.18-5.26-13.54-11.52-33.89-13.23-71.35-1.85-40.52-2.24-52.67-2.24-155.26s.39-114.75 2.24-155.26c1.71-37.46 7.97-57.81 13.23-71.35 6.97-17.94 15.3-30.74 28.74-44.18 13.45-13.45 26.25-21.77 44.18-28.74 13.54-5.26 33.89-11.52 71.35-13.23 40.52-1.85 52.67-2.24 155.26-2.24"
      ></path>
      <path
        fill="var(--blue)"
        d="M500.15 614.46c-70.74 0-128.08-57.34-128.08-128.08S429.41 358.3 500.15 358.3s128.08 57.34 128.08 128.08-57.34 128.08-128.08 128.08zm0-325.39c-108.97 0-197.31 88.34-197.31 197.31s88.34 197.31 197.31 197.31 197.31-88.34 197.31-197.31-88.34-197.31-197.31-197.31m251.21-7.8c0 25.47-20.64 46.11-46.11 46.11s-46.11-20.64-46.11-46.11 20.64-46.11 46.11-46.11 46.11 20.64 46.11 46.11"
      ></path>
    </svg>
  );
}

export default Icon;
