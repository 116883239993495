import React from "react";
import { ChangeEventHandler, SelectHTMLAttributes } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  align-items: flex-start;
  padding: 0.25em;
`;
const InputWrapper = styled.div`
  position: relative;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    right: 0.5em;
    top: 0.75em;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.4em 0.35em 0 0.35em;
    border-color: var(--silver) transparent transparent transparent;
  }
`;
const Border = styled.div`
  pointer-events: none;
  position: absolute;
  left: -0.25em;
  top: -0.25em;
  width: calc(100% + 0.5em);
  height: calc(100% + 0.5em);
  border-radius: 0.5em;
  border: 2px solid rgba(255, 255, 255, 0.4);
`;
const NativeSelect = styled.select`
  padding: 0em 0.5em;
  padding-bottom: 0.15em;
  font-size: 1em;
  border-radius: 0.25em;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  width: 100%;
  height: 2em;
  appearance: none;

  &:focus {
    outline: none;
    background: var(--focus-background);
  }

  &:focus + ${Border} {
    border-color: var(--mint);
  }

  &[aria-invalid="true"] {
    background: var(--error-background);
  }

  &[aria-invalid="true"] + ${Border} {
    border-color: var(--error-color);
  }
`;
const NativeOption = styled.option``;
const Label = styled.label`
  font-size: 0.8em;
  font-weight: 600;
  margin-bottom: 0.5em;
  margin-left: -0.25em;
`;
const ErrorText = styled.p`
  font-size: 0.65em;
  margin: 0px;
  margin-top: 0.65em;
  margin-bottom: -0.65em;
  margin-left: -0.25em;
  font-style: italic;
  color: var(--error-color);
`;

export type Option = {
  value: string;
  label: string;
};

type TextInputProps = Omit<
  SelectHTMLAttributes<HTMLSelectElement>,
  "label" | "value" | "onChange" | "error"
> & {
  label: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  options: Option[];
};

export const Select = ({
  options,
  label,
  error,
  value,
  onChange,
}: TextInputProps) => {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = React.useCallback(
    (e) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <Wrapper>
      <Label>{label}</Label>
      <InputWrapper>
        <NativeSelect value={value} onChange={handleChange}>
          {options.map((option) => (
            <NativeOption value={option.value} key={option.value}>
              {option.label}
            </NativeOption>
          ))}
        </NativeSelect>
        <Border />
      </InputWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  );
};
