import { format, parse } from "date-fns";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Product } from "../../schema";
import {
  JsonOrder,
  Order,
  OrderProduct,
  ProductSchemasMap,
  StepSchema,
  StepsValue,
  UploadStatus,
} from "./../../types";
import { useOrder, useProductSchemas } from "./OrderForm";

export const useCurrentProduct = () => {
  const { productId } = useParams();
  const { products: productSchemas, isLoading } = useProductSchemas();

  const productSchema = productSchemas
    ? productSchemas[productId ?? ""]
    : undefined;

  return { productSchema, productId, isLoading };
};

export const useRedirectIfNullProduct = () => {
  const { productSchema, productId, isLoading } = useCurrentProduct();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!productId || (!isLoading && !productSchema)) {
      navigate("/order/products/add");
    }
  }, [productSchema, isLoading, productId, navigate]);
};

export const useExistingProduct = () => {
  const { orderProductId } = useParams();
  const { order, setOrder } = useOrder();

  const product = React.useMemo(
    () => order.products.find((p) => p.id === orderProductId),
    [order.products, orderProductId]
  );

  const setProduct = React.useCallback(
    (newProduct: OrderProduct) => {
      if (setOrder) {
        setOrder((x) => {
          const index = x.products.findIndex((p) => p.id === newProduct.id);

          if (index === -1) return x;

          return {
            ...x,
            products: [
              ...x.products.slice(0, index),
              newProduct,
              ...x.products.slice(index + 1),
            ],
          };
        });
      }
    },
    [setOrder]
  );

  return {
    product,
    setProduct,
  };
};

const getStepMarkdown = (schema: StepSchema, step: StepsValue): string => {
  if (schema._type === "cakeSize") {
    const height = schema.heights.find(
      (x) => x._key === step.cakeSize?.heightId
    );
    const diameter = height?.diameters.find(
      (x) => x._key === step.cakeSize?.diameterId
    );
    return `**Height**: ${height?.name ?? "Cake height not picked"}
**Diameter**: ${diameter?.inches ?? "Diameter not picked"}`;
  }

  if (schema._type === "quantity") {
    return `**Quantity**: ${step.quantity}`;
  }

  if (schema._type === "decoration") {
    return `**Instructions**:
${step.decoration?.instructions || "No instructions provided"}
**Photos**:
${
  step.decoration?.photos.length
    ? step.decoration?.photos
        .filter((photo) => photo.status === UploadStatus.Done && photo.url)
        .map((photo, i) => `[Click to view photo ${i + 1}](${photo.url})`)
        .join("\n") ?? "No photos uploaded"
    : "No photos uploaded"
}`;
  }

  if (schema._type === "flavors") {
    if (step.flavors?.customFlavor) {
      return `**Custom Flavor**: ${step.flavors.customFlavor}`;
    }
    return step.flavors?.selected
      ? `**Flavor**: ${step.flavors?.selected}`
      : "No flavor selected";
  }

  return "";
};

const getProductMarkdown = (
  schema: Product,
  product: OrderProduct,
  productNum: number
): string => {
  return `### ${productNum}. ${schema.name}
${schema.orderFields
  .map((step) => getStepMarkdown(step, product.value))
  .join("\n")}`;
};

export const getOrderJson = (
  order: Order,
  products: ProductSchemasMap
): JsonOrder => {
  return {
    contact: order.contact,
    dueDate: order.dueDate
      ? format(parse(order.dueDate, "yyyy-MM-dd", new Date()), "MMM, d yyyy")
      : "Not Provided",
    dueTime: order.dueTime ?? "Not Provided",
    deliveryMethod: order.deliveryMethod ?? "No method picked",
    products: order.products
      .map((prod, i) =>
        getProductMarkdown(products[prod.productId], prod, i + 1)
      )
      .join("\n\n"),
    deliveryAddress: order.deliveryAddress
      ? `${order.deliveryAddress}\n${order.deliveryCity}, ND`
      : "Not Provided",
  };
};
