function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
      <path fill="none" d="M0.15 0H1000.15V1000H0.15z"></path>
      <ellipse
        cx="500.15"
        cy="215.41"
        fill="#eaf5f4"
        stroke="#ffa4c9"
        strokeMiterlimit="10"
        strokeWidth="20"
        rx="267.23"
        ry="98.53"
      ></ellipse>
      <path
        fill="#eaf5f4"
        stroke="#ffa4c9"
        strokeMiterlimit="10"
        strokeWidth="20"
        d="M767.39 215.41v569.18c0 54.43-119.64 98.53-267.23 98.53s-267.23-44.1-267.23-98.53V215.41c0 54.41 119.64 98.53 267.23 98.53s267.23-44.12 267.23-98.53z"
      ></path>
    </svg>
  );
}

export default Icon;
