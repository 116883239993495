import { SVGAttributes } from "react";

function Icon(props: SVGAttributes<SVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <path d="M695.34 591.84L695.34 591.84 695.35 591.85 695.34 591.84z"></path>
      <path d="M803.49 699.99L695.34 591.84A123.32 123.32 0 00614.77 558a123.306 123.306 0 00-82.76 28.04l-78.08 78.08 7.18 7.18a47.223 47.223 0 0031.18 11.32 50.03 50.03 0 0030.35-10.48l4.97-6.62 33.66-33.66c13.18-12.52 30.66-19.49 48.84-19.49s35.66 6.97 48.83 19.49L796.9 769.82a55.188 55.188 0 01-19.4 23.87 55.11 55.11 0 01-29.16 9.78H313.77l-55.18 55.18H748.3c29.27 0 57.34-11.62 78.04-32.31a110.415 110.415 0 0032.32-78.04V258.56l-55.18 55.18v386.25z"></path>
      <path d="M617.53 499.97h6.62A124.418 124.418 0 00748.3 375.82c.14-2.21.14-4.42 0-6.62L617.52 499.98zM414.53 624.49l.21.21 39.18-39.18-.21-.21 104.43-104.43s.05.03.07.05l40.83-40.83s-.06-.02-.09-.04l89.44-89.44s.02.06.04.09l40.83-40.83s-.03-.05-.05-.07l74.27-74.27v.14l46.91-46.92s-.03-.06-.04-.09l96.79-96.79-39.02-39.02-96.66 96.66a82.83 82.83 0 00-35.56-8.22H224.09c-21.95 0-43 8.72-58.52 24.25a82.727 82.727 0 00-24.25 58.52v551.81a82.83 82.83 0 008.22 35.56l-96.69 96.69 39.02 39.02 96.81-96.81s.06.03.09.04l46.9-46.9h-.13l178.99-178.99zM556.7 389.98a68.943 68.943 0 0118.75-62.87 68.943 68.943 0 0173.88-15.47l-89.35 89.35a68.178 68.178 0 01-3.28-11.01zM196.51 224.09c0-15.23 12.35-27.58 27.58-27.58h540.39l-74.25 74.25a124.136 124.136 0 00-66.07-19.1c-36.14.04-70.47 15.83-94.03 43.23-23.56 27.41-34 63.72-28.61 99.46 2.58 17.07 8.66 33.19 17.63 47.48L414.69 546.29 364.8 496.4c-44.2-43.71-107.81-61.54-168.29-47.18V224.09zm0 283.08l7.45-3.31c21.27-6 43.76-6.22 65.14-.65a124.321 124.321 0 0156.53 32.37l49.89 49.89-179 179V507.16z"></path>
    </svg>
  );
}

export default Icon;
