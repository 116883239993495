import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useExistingProduct, useRedirectIfNullProduct } from "./productHooks";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1em;
`;

export const EditProduct = () => {
  useRedirectIfNullProduct();
  const navigate = useNavigate();
  const { product } = useExistingProduct();

  React.useEffect(() => {
    if (!product) {
      navigate("/order/products");
    }
  }, [product, navigate]);

  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};
