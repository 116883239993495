import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FormControls } from "../../components/FormControls";
import { TextInput } from "../../components/TextInput";
import { Order } from "../../types";
import { useOrder } from "./OrderForm";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Fields = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

type FormErrors = { [fieldName: string]: string };

export const getContactStepErrors = (order: Order): FormErrors => {
  let errors: { [fieldName: string]: string } = {};

  if (!order.contact.name.length) {
    errors.name =
      "Sorry, we don't bake for strangers. Please let us know who you are.";
  }
  if (order.contact.phone.length !== 10) {
    errors.phone = "Please enter a 10-digit phone number";
  }
  if (
    !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(order.contact.email)
  ) {
    errors.email = "Please enter a valid email";
  }

  return errors;
};

export const ContactInfoForm = () => {
  const { order, setContact } = useOrder();
  const navigate = useNavigate();
  const [errors, setErrors] = React.useState<FormErrors>({});

  const handleChange = React.useCallback(
    (name: string) => (value: string) => {
      if (errors[name]) {
        setErrors(({ [name]: _removed, ...newErrors }) => newErrors);
      }
      setContact((customer) => ({ ...customer, [name]: value }));
    },
    [setContact, errors]
  );

  const formatPhone = (value: string) => {
    if (value.length) {
      const area = value.slice(0, 3);
      const p1 = value.slice(3, 6);
      const p2 = value.slice(6, 10);

      return `${area.length ? `(${area}` : ""}${p1.length ? `) ${p1}` : ``}${
        p2.length ? `-${p2}` : ``
      }`;
    }
    return "";
  };

  const validateAndProceed = () => {
    const errors = getContactStepErrors(order);

    if (Object.keys(errors).length) {
      setErrors(errors);
    } else {
      navigate("../products");
    }
  };

  return (
    <Wrapper>
      <p>
        You're just a few steps away from delicious baked treats! Let's start by
        getting some basic information from you.
      </p>
      <Fields onSubmit={(e) => e.preventDefault()}>
        <TextInput
          label="Your Name"
          value={order.contact.name}
          onChange={handleChange("name")}
          autoComplete="name"
          error={errors.name}
        />
        <TextInput
          label="Phone Number"
          value={formatPhone(order.contact.phone)}
          onChange={(rawValue) => {
            let numerals = rawValue.replace(/\D/g, "");

            if (numerals.length > 10 && numerals[0] === "1") {
              numerals = numerals.slice(1);
            }

            handleChange("phone")(numerals);
          }}
          autoComplete="tel"
          type="tel"
          error={errors.phone}
        />
        <TextInput
          label="Email"
          value={order.contact.email}
          onChange={handleChange("email")}
          type="email"
          autoComplete="email"
          error={errors.email}
        />
      </Fields>
      <FormControls onNext={validateAndProceed} />
    </Wrapper>
  );
};
