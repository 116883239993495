import styled from "styled-components";
import { Select } from "../../components/Select";
import { TextArea } from "../../components/TextArea";
import { FlavorValue } from "../../types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

interface FlavorPickerProps {
  flavors: string[];
  value: FlavorValue;
  onChange: (flavor: FlavorValue) => void;
}

export const FlavorPicker = ({
  flavors,
  value,
  onChange,
}: FlavorPickerProps) => {
  return (
    <Wrapper>
      <Select
        label="Pick a curated flavor"
        value={value.selected}
        onChange={(selected) => onChange({ ...value, selected })}
        options={[
          { value: "", label: "[Pick a Flavor]" },
          ...flavors.map((x) => ({ value: x, label: x })),
        ]}
      />
      <TextArea
        label="Or, create your own flavor"
        description="If you do not see your favorite cake listed and would like to create your own, tell us what you are looking for. We will see what we can do!"
        value={value.customFlavor}
        onChange={(customFlavor) => onChange({ ...value, customFlavor })}
      />
    </Wrapper>
  );
};
