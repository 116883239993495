import * as Schema from "./schema";

export type CakeSizeStep = Schema.SanityKeyed<Schema.CakeSize>;
export type QuantityStep = Schema.SanityKeyed<Schema.Quantity>;
export type FlavorsStep = Schema.SanityKeyed<Schema.Flavors>;
export type DecorationStep = Schema.SanityKeyed<Schema.Decoration>;
export type StepSchema =
  | CakeSizeStep
  | QuantityStep
  | FlavorsStep
  | DecorationStep;
export type StepsMap = Record<string, StepSchema | undefined>;

export type CakeSize = {
  heightId: string | undefined;
  diameterId: string | undefined;
};

export type Customer = {
  name: string;
  phone: string;
  email: string;
};

export type FlavorValue = {
  selected: string;
  customFlavor: string;
};

export enum UploadStatus {
  Pending = "pending",
  Uploading = "uploading",
  Done = "done",
  Error = "error",
}

export type PhotoUpload = {
  id: string;
  file: File;
  status: UploadStatus;
  name: string;
  error?: string;
  url?: string;
};

export type DecorationValue = {
  instructions: string;
  photos: PhotoUpload[];
};

export type StepsValue = {
  cakeSize?: CakeSize;
  quantity?: number;
  flavors?: FlavorValue;
  decoration?: DecorationValue;
};

export type OrderProduct = {
  id: string;
  productId: string;
  value: StepsValue;
};

export type Order = {
  contact: Customer;
  dueDate: string;
  dueTime: string;
  deliveryMethod: null | "pickup" | "delivery";
  products: OrderProduct[];
  deliveryAddress: string;
  deliveryCity: string;
};

export type JsonOrder = {
  contact: Customer;
  dueDate: string;
  dueTime: string;
  deliveryMethod: string;
  products: string;
  deliveryAddress: string;
};

export type ProductSchemasMap = {
  [productId: string]: Schema.Product;
};
