import sanityClient from "@sanity/client";
import SanityImageUrlBuilder from "@sanity/image-url";
import sortBy from "lodash/sortBy";
import * as Schema from "./schema";

const SANITY_PROJECT_ID = "s3p15mz8";
const SANITY_DATASET = "production";

const api = sanityClient({
  projectId: SANITY_PROJECT_ID,
  dataset: SANITY_DATASET,
  apiVersion: "2022-08-23", // use current UTC date - see "specifying API version"!
  token: "", // or leave blank for unauthenticated usage
  useCdn: false, // `false` if you want to ensure fresh data
});

const ImageBuilder = SanityImageUrlBuilder(api);

export const getProductSchemas = async () => {
  const products = await api.fetch<Schema.Product[]>('*[_type == "product"]');
  return products;
};

const createMap = <T extends Schema.Documents>(sanityObject: T[]) =>
  sanityObject.reduce<{ [id: string]: T }>((acc, obj) => {
    acc[obj._id] = obj;
    return acc;
  }, {});

export const getSchemas = async () => {
  const productsArray = await getProductSchemas();
  const products = createMap<Schema.Product>(productsArray);
  const productsOrder = sortBy(productsArray, ["sortIndex"]).map((x) => x._id);

  return {
    products,
    productsOrder,
  };
};

export const getSanityImage = (
  imageAsset: Schema.SanityReference<Schema.SanityImageAsset>
) => ImageBuilder.image(imageAsset);
