function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
      <path fill="none" d="M0 0H1000V1000H0z"></path>
      <ellipse
        cx="500"
        cy="316.57"
        fill="#eaf5f4"
        stroke="#ffa4c9"
        strokeMiterlimit="10"
        strokeWidth="20"
        rx="283.52"
        ry="105.24"
      ></ellipse>
      <path
        fill="#eaf5f4"
        stroke="#ffa4c9"
        strokeMiterlimit="10"
        strokeWidth="20"
        d="M783.52 316.57v366.86c0 58.14-126.93 105.24-283.52 105.24s-283.52-47.1-283.52-105.24V316.57c0 58.11 126.93 105.24 283.52 105.24s283.52-47.12 283.52-105.24z"
      ></path>
    </svg>
  );
}

export default Icon;
