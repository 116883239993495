import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useCurrentProduct, useRedirectIfNullProduct } from "./productHooks";
import React from "react";
import { StepSchema, StepsMap } from "../../types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1em;
`;

export const useCurrentStep = () => {
  const { stepId } = useParams();
  const { productSchema } = useCurrentProduct();

  const stepMap = React.useMemo(() => {
    if (!productSchema) return {};
    return productSchema.orderFields.reduce<StepsMap>((acc, step) => {
      acc[step._key] = step;
      return acc;
    }, {});
  }, [productSchema]);

  const currentStep: StepSchema | undefined = stepMap[stepId ?? ""];
  const currentStepIndex = React.useMemo(
    () =>
      productSchema?.orderFields?.findIndex(
        (step) => step._key === currentStep?._key
      ) ?? -1,
    [currentStep, productSchema]
  );
  const nextStep: StepSchema | undefined =
    productSchema?.orderFields[currentStepIndex + 1];

  return {
    steps: stepMap,
    currentStep,
    nextStep,
  };
};

export const AddProduct = () => {
  useRedirectIfNullProduct();
  const { currentStep } = useCurrentStep();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!currentStep) {
      navigate("/order/products/add");
    }
  }, [currentStep, navigate]);

  return (
    <Wrapper>
      <Outlet />
    </Wrapper>
  );
};
