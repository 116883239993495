import { Link } from "react-router-dom";
import styled from "styled-components";
import tileSrc from "../img/sprinkles-tile.svg";
import headerLogo from "../img/web_header.svg";
import headerLogoMobile from "../img/web_header_mobile.svg";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 600px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  padding: 10px 0px;
  max-width: 100vw;
  width: 100%;

  @media (max-width: 600px) {
    display: flex;
    padding: 10px;
  }
`;
const Logo = styled.img`
  width: 100%;
  max-width: 600px;
  flex-basis: 600px;
  flex-shrink: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const MobileLogo = styled.img`
  display: none;
  width: 100%;
  @media (max-width: 960px) {
    display: block;
  }
`;
const HomeLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Tile = styled.div`
  flex-grow: 1;
  background: url(${tileSrc});
  background-repeat: repeat-x;
  background-size: contain;
  height: 100%;

  @media (max-width: 1435px) {
    background-size: cover;
  }
`;

export const Header = () => (
  <Wrapper>
    <Tile style={{ backgroundPosition: "right" }} />
    <HomeLink to="/">
      <Logo src={headerLogo} alt="The Suger Mill" />
      <MobileLogo src={headerLogoMobile} alt="The Suger Mill" />
    </HomeLink>
    <Tile />
  </Wrapper>
);
