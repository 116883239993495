import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Header } from "../../components/Header";

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const App = () => (
  <Main>
    <Header />
    <Outlet />
  </Main>
);
