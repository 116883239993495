import React from "react";
import styled from "styled-components";
import { useCurrentStep } from "../pages/Order/AddProduct";
import { Step, STEPS_ORDER } from "../pages/Order/OrderForm";
import { useCurrentProduct } from "../pages/Order/productHooks";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.h1`
  font-family: var(--font-secondary);
  font-weight: 800;
  font-size: 28px;
  margin: 0px;
`;
const ProductTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  color: var(--silver);

  &:hover {
    text-decoration: underline;
  }
`;
const Segments = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
`;
const Segment = styled.div`
  flex: 1;
  background: var(--silver);
  height: 6px;
  border-radius: 4px;
  opacity: 0.3;
`;

interface ProgressHeaderProps {
  step: Step;
}

export const ProgressHeader = ({ step }: ProgressHeaderProps) => {
  const { currentStep: currentProductStep } = useCurrentStep();
  const { productSchema } = useCurrentProduct();

  const stepIndex = React.useMemo(
    () => STEPS_ORDER.findIndex((x) => x === step.id),
    [step]
  );

  if (step.hideHeader) return null;

  return (
    <Wrapper>
      <TitleWrapper>
        {currentProductStep && productSchema && (
          <ProductTitle>{productSchema.name}</ProductTitle>
        )}
        <Title>{currentProductStep?.title || step.title}</Title>
      </TitleWrapper>
      <Segments>
        {STEPS_ORDER.map((id, index) => {
          const isPassed = index <= stepIndex;
          return (
            <Segment
              key={id}
              style={{
                background: isPassed ? "var(--mint)" : "",
                opacity: isPassed ? 1 : "",
              }}
            />
          );
        })}
      </Segments>
    </Wrapper>
  );
};
