import ImageKit from "imagekit-javascript";
import { nanoid } from "nanoid/non-secure";
import React, { ChangeEventHandler } from "react";
import styled from "styled-components";
import { TextArea } from "../../components/TextArea";
import BadImage from "../../img/BadImage";
import ImageAdd from "../../img/ImageAdd";
import { DecorationValue, PhotoUpload, UploadStatus } from "../../types";

var imagekit = new ImageKit({
  publicKey: "public_CCjgu9zcmvDmQnpv/lVRyXyd0Tw=",
  urlEndpoint: "https://ik.imagekit.io/sugarmill",
  authenticationEndpoint:
    window.location.protocol +
    window.location.host +
    "/.netlify/functions/imagekit-auth",
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
const PhotosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;
const HiddenFileInput = styled.input`
  appearance: none;
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
  pointer-events: none;
`;
const FileUpload = styled.label`
  display: flex;
  algin-items: center;
  font-size: 16px;
  font-weight: 600;
  border: 2px dotted var(--silver);
  padding: 0.35em;
  border-radius: 0.65em;
  cursor: pointer;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.25em;
`;
const UploadHint = styled.p`
  margin: 0px;
  font-size: 0.75em;
  font-style: italic;
  font-weight: 400;
`;
const PhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  background: var(--silver);
  color: var(--blue);
  padding: 0.35em;
  border-radius: 0.65em;
  overflow: hidden;
  box-shadow: 0.5em 0.5em rgba(0, 0, 0, 0.2);

  &[data-status="error"] {
    background: var(--red);
    color: var(--silver);

    button {
      background: var(--silver);
      color: var(--red);
    }
  }
`;
const Thumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  height: 3em;
  border-radius: 0.3em;
  background-size: cover;
  background-origin: center center;
  flex-shrink: 0;
  fill: var(--silver);

  svg {
    width: 100%;
    max-height: 3em;
  }
`;
const FilenameWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Filename = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const PhotoControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  margin-left: auto;
`;
const RemoveButton = styled.button`
  padding: 0px;
  padding-bottom: 0.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: var(--blue);
  border-radius: 100%;
  width: 1em;
  height: 1em;
  font-size: 1.5em;
  line-height: 0;
  box-shadow: 0.15em 0.15em 0px rgba(0, 0, 0, 0.2);
`;
const PhotosLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 24px;
  gap: 0.25em;
`;
const Label = styled.label`
  font-size: 0.8em;
  font-weight: 600;
`;
const Description = styled.p`
  font-size: 0.5em;
  margin: 0px;
  opacity: 0.8;
`;
const ProgressWrapper = styled.div`
  flex: 1;
`;
const Progress = styled.div`
  height: 0.75em;
  border-radius: 0.5em;
  background: var(--mint);
  transition: width 50ms;
`;

interface DecorationProps {
  value: DecorationValue;
  onChange: (value: DecorationValue) => void;
}

const MAX_FILE_SIZE = 5000000;

export const Decoration = ({ value, onChange }: DecorationProps) => {
  const numNonErroredPhotos = React.useMemo(
    () =>
      value.photos.filter((photo) => photo.status !== UploadStatus.Error)
        .length,
    [value.photos]
  );

  const handleImageSelected: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;

    if (!files) return;

    if (files.length > 3 - numNonErroredPhotos) {
      // Display more than 3 error
      return;
    }

    const photos: PhotoUpload[] = Array.from(files).map<PhotoUpload>((file) => {
      const isTooBig = file.size > MAX_FILE_SIZE;

      return {
        id: nanoid(8),
        file,
        name: file.name,
        status: isTooBig ? UploadStatus.Error : UploadStatus.Pending,
        error: isTooBig ? "This photo is too big, max 5mb" : "",
      };
    });

    e.target.files = null;

    onChange({ ...value, photos: [...value.photos, ...photos] });
  };

  const handlePhotoChange = (photo: PhotoUpload) => {
    const index = value.photos.findIndex((x) => x.id === photo.id);

    onChange({
      ...value,
      photos: [
        ...value.photos.slice(0, index),
        photo,
        ...value.photos.slice(index + 1),
      ],
    });
  };

  const handlePhotoDelete = (photo: PhotoUpload) => {
    const index = value.photos.findIndex((x) => x.id === photo.id);

    onChange({
      ...value,
      photos: [
        ...value.photos.slice(0, index),
        ...value.photos.slice(index + 1),
      ],
    });
  };

  return (
    <Wrapper>
      <div style={{ marginBottom: ".5em" }}>
        <TextArea
          label="Optional: Decorating Instructions"
          description="Describe how you would like your treats decorated? Colors, themes, etc..."
          value={value.instructions ?? ""}
          onChange={(instructions) => onChange({ ...value, instructions })}
        />
      </div>
      <PhotosLabelWrapper>
        <Label>Optional: Photo Inspiration</Label>
        <Description>
          Attach any photo inspiration you may have - colors, invitations, party
          favors, tableware, or anything that helps us catch the vibe of your
          event!
        </Description>
        <Description>
          Please note that all our creations are original! We cannot replicate
          someone else's art exactly. If you have photos of baked goods you'd
          like to submit, we will use them as inspiration, but not replication.
        </Description>
      </PhotosLabelWrapper>
      <PhotosWrapper>
        {value.photos.map((photo) => (
          <PhotoDisplay
            photo={photo}
            onChange={handlePhotoChange}
            onRequestDelete={() => handlePhotoDelete(photo)}
            key={photo.id}
          />
        ))}
        {numNonErroredPhotos < 3 && (
          <FileUpload>
            <Thumbnail>
              <ImageAdd
                style={{
                  maxHeight: "2.5em",
                  maxWidth: "2.5em",
                  marginLeft: "-.25em",
                }}
              />
            </Thumbnail>
            <Column>
              <span>Upload a File</span>
              <UploadHint>
                Max 5mb per upload. Up to 3 photos allowed.
              </UploadHint>
            </Column>
            <HiddenFileInput
              type="file"
              onChange={handleImageSelected}
              accept=".jpg, .jpeg, .png"
              multiple
            />
          </FileUpload>
        )}
      </PhotosWrapper>
    </Wrapper>
  );
};

interface PhotoDisplayProps {
  photo: PhotoUpload;
  onChange: (photo: PhotoUpload) => void;
  onRequestDelete: () => void;
}

const PhotoDisplay = ({
  photo,
  onChange,
  onRequestDelete,
}: PhotoDisplayProps) => {
  const { file, name, status, error } = photo;
  const [uploadPercent, setUploadPercent] = React.useState(0);
  const [thumbnailSrc, setThumbnailSrc] = React.useState<string | null>(null);

  React.useEffect(() => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const url = reader.result;

      if (typeof url === "string") {
        setThumbnailSrc(url);
      }
    };
    reader.readAsDataURL(file);
  }, [file]);

  React.useEffect(() => {
    if (photo.status === UploadStatus.Pending) {
      var XHRTracker = new XMLHttpRequest();
      XHRTracker.upload.addEventListener("progress", function (e) {
        if (e.loaded <= photo.file.size) {
          var percent = Math.round((e.loaded / photo.file.size) * 100);
          setUploadPercent(percent);
        }
      });

      onChange({ ...photo, status: UploadStatus.Uploading });

      imagekit
        .upload({
          file: photo.file,
          folder: "order_form",
          fileName: "user_upload",
          xhr: XHRTracker,
        })
        .then(({ url }) => {
          setUploadPercent(100);
          onChange({ ...photo, status: UploadStatus.Done, url });
        })
        .catch((err) => {
          console.error(err);
          onChange({
            ...photo,
            status: UploadStatus.Error,
            error: "Something went wrong, please try again.",
          });
        });
    }
  }, [photo, onChange]);

  return (
    <PhotoWrapper data-status={status}>
      {thumbnailSrc && !error ? (
        <Thumbnail
          style={{
            backgroundImage: `url(${thumbnailSrc})`,
          }}
        />
      ) : (
        <Thumbnail>
          <BadImage
            style={{
              maxHeight: "2.5em",
              maxWidth: "2.5em",
              marginLeft: "-.25em",
            }}
          />
        </Thumbnail>
      )}
      {status !== UploadStatus.Uploading ? (
        <FilenameWrapper>
          <Filename>{error || name}</Filename>
        </FilenameWrapper>
      ) : (
        <ProgressWrapper>
          <Progress style={{ width: `${uploadPercent}%` }} />
        </ProgressWrapper>
      )}
      <PhotoControls>
        <RemoveButton onClick={onRequestDelete}>×</RemoveButton>
      </PhotoControls>
    </PhotoWrapper>
  );
};
