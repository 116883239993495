import cloneDeep from "clone-deep";
import { addDays, format } from "date-fns";
import React from "react";
import {
  NavigateFunction,
  Outlet,
  Pathname,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import { getSchemas } from "../../api";
import { ProgressHeader } from "../../components/ProgressHeader";
import { usePrevious } from "../../hooks";
import { Customer, Order, OrderProduct, ProductSchemasMap } from "../../types";
import { getContactStepErrors } from "./ContactInfoForm";

const EMPTY_ORDER: Order = {
  contact: {
    name: "",
    email: "",
    phone: "",
  },
  deliveryMethod: null,
  dueDate: format(addDays(new Date(), 3), "yyyy-MM-dd"),
  dueTime: "12:00",
  products: [],
  deliveryAddress: "",
  deliveryCity: "",
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex: 1;
  max-width: 600px;
  width: 100%;

  @media (min-width: 600px) {
    padding-top: 50px;
  }
`;

const orderContext = React.createContext<Order>(cloneDeep(EMPTY_ORDER));
const setOrderContext = React.createContext<React.Dispatch<
  React.SetStateAction<Order>
> | null>(null);
const productSchemasContext = React.createContext<ProductSchemasMap | null>(
  null
);
const productSchemasOrderContext = React.createContext<string[] | null>(null);

export type Step = {
  id: string;
  title: string;
  onStepLoaded?: (order: Order, navigate: NavigateFunction) => void;
  hideHeader?: boolean;
};

export const STEPS: { [stepId: string]: Step } = {
  contact: {
    id: "contact",
    title: "Contact Info",
  },
  products: {
    id: "products",
    title: "Pick Your Treats",
    onStepLoaded: (order: Order, navigate: NavigateFunction) => {
      if (Object.keys(getContactStepErrors(order)).length) {
        navigate("/order/contact", { replace: true });
      } else if (!order.products.length) {
        navigate("/order/products/add", { replace: true });
      }
    },
  },
  delivery: {
    id: "delivery",
    title: "Delivery",
    onStepLoaded: (order: Order, navigate: NavigateFunction) => {
      if (Object.keys(getContactStepErrors(order)).length) {
        navigate("/order/contact", { replace: true });
      } else if (!order.products.length) {
        navigate("/order/products", { replace: true });
      }
    },
  },
};

export const STEPS_ORDER: string[] = ["contact", "products", "delivery"];

export const useOrder = () => {
  const order = React.useContext(orderContext);
  const setOrder = React.useContext(setOrderContext);

  const setContact = (
    customer: ((currentCustomer: Customer) => Customer) | Customer
  ) =>
    setOrder?.((x) => ({
      ...x,
      contact: typeof customer === "function" ? customer(x.contact) : customer,
    }));

  const deleteProduct = (product: OrderProduct) => {
    const index = order.products.findIndex((p) => p.id === product.id);

    if (index !== -1) {
      setOrder?.((order) => ({
        ...order,
        products: [
          ...order.products.slice(0, index),
          ...order.products.slice(index + 1),
        ],
      }));
    }
  };

  return {
    order,
    setOrder,
    setContact,
    deleteProduct,
  };
};

export const useCustomer = () => useOrder().order.contact;

export const useProductSchemas = () => {
  const products = React.useContext(productSchemasContext);
  const productsOrder = React.useContext(productSchemasOrderContext);

  const orderedProducts = React.useMemo(() => {
    if (products && productsOrder) {
      return productsOrder.map((productId) => products[productId]);
    }
    return [];
  }, [products, productsOrder]);

  return {
    products,
    productsOrder,
    orderedProducts,
    isLoading: !products || !productsOrder,
  };
};

const getCurrentStep = (path: Pathname) => {
  const parts = path.split("/");
  const orderIndex = parts.findIndex((x) => x === "order");

  if (orderIndex === -1) return undefined;

  return STEPS[parts[orderIndex + 1]];
};

export const OrderForm = () => {
  const [order, setOrder] = React.useState(cloneDeep(EMPTY_ORDER));
  const [productSchemas, setProductSchemas] =
    React.useState<ProductSchemasMap | null>(null);
  const [productSchemasOrder, setProductSchemasOrder] = React.useState<
    string[] | null
  >(null);
  const navigate = useNavigate();
  const location = useLocation();
  const currentStep = getCurrentStep(location.pathname);
  const previousStep = usePrevious<Step | undefined>(currentStep);

  React.useEffect(() => {
    if (!currentStep) {
      navigate("/order/contact", { replace: true });
    }
    // Only run if the step has changed
    if (currentStep?.id !== previousStep?.id) {
      currentStep?.onStepLoaded?.(order, navigate);
    }
  }, [currentStep, previousStep, navigate, order]);

  React.useEffect(() => {
    if (!productSchemas && !productSchemasOrder) {
      const fetchData = async () => {
        const schemas = await getSchemas();
        setProductSchemas(schemas.products);
        setProductSchemasOrder(schemas.productsOrder);
      };
      fetchData();
    }
  }, [productSchemas, productSchemasOrder]);

  if (!currentStep) return null;

  return (
    <Wrapper>
      <productSchemasContext.Provider value={productSchemas}>
        <productSchemasOrderContext.Provider value={productSchemasOrder}>
          <orderContext.Provider value={order}>
            <setOrderContext.Provider value={setOrder}>
              <ProgressHeader step={currentStep} />
              <Outlet />
            </setOrderContext.Provider>
          </orderContext.Provider>
        </productSchemasOrderContext.Provider>
      </productSchemasContext.Provider>
    </Wrapper>
  );
};
