import styled from "styled-components";
import { FloatingCTAButton } from "../../components/FloatingCTAButton";
import { ImageGallery } from "../../components/ImageGallery";
import "./Home.css";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Callout = styled.p`
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
  margin-left: -0.8ch;

  @media (min-width: 650px) {
    font-size: 48px;
  }
`;
const Intro = styled.p`
  text-align: center;
  padding: 0 1em;
  font-size: 20px;
  line-height: 1.6;
  margin: 1em 0;
  max-width: 650px;

  @media (min-width: 650px) {
    font-size: 24px;
  }
`;

function App() {
  return (
    <Wrapper className="App">
      <Callout>👋 Hey there!</Callout>
      <Intro>
        Welcome to The Sugar Mill! We're a local bakery in{" "}
        <strong>Fargo, North Dakota</strong>, and we love making gourmet goodies
        for parties, events, and the occasional sweet tooth cravings.
      </Intro>
      <ImageGallery />
      <FloatingCTAButton href="/order">Order Now</FloatingCTAButton>
    </Wrapper>
  );
}

export default App;
