import { SVGAttributes } from "react";

function Icon(props: SVGAttributes<SVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" {...props}>
      <path d="M804.59 749.71L804.59 694.51 749.42 694.51 749.42 749.71 694.25 749.71 694.25 804.88 749.42 804.88 749.42 860.05 804.59 860.05 804.59 804.88 859.78 804.88 859.78 749.71 804.59 749.71z"></path>
      <path d="M937.27 668.58a193.109 193.109 0 00-111.42-78.07 169.275 169.275 0 00-31.45-5.53c-5.78-.57-11.58-.85-17.39-.82H763.5c-10.04.62-20.01 2.1-29.8 4.42a193.156 193.156 0 00-98.46 57.81 193.02 193.02 0 00-48.6 103.32 173.13 173.13 0 000 55.18 193.153 193.153 0 0065.44 119.28c35.56 30.5 81 47.04 127.84 46.53 46.85-.51 91.91-18.02 126.81-49.29a193.074 193.074 0 0062.83-120.66c5.63-46.51-5.86-93.48-32.29-132.15zM891.7 854.17c-25.64 38.22-68.66 61.13-114.69 61.08-31.8 0-62.63-10.97-87.27-31.09a138.043 138.043 0 01-47.93-79.28 138.176 138.176 0 010-55.18 138.055 138.055 0 0137.62-69.99 137.945 137.945 0 0169.98-37.62c18.21-3.72 36.98-3.72 55.19 0 45.1 9.15 82.67 40.2 100.16 82.78 17.48 42.58 12.58 91.07-13.06 129.28z"></path>
      <path d="M225.22 142.74c-21.96 0-43.01 8.72-58.53 24.24a82.82 82.82 0 00-24.24 58.36v552.12c.04 21.9 8.76 42.88 24.24 58.36a82.799 82.799 0 0057.96 24.23h303.77l.09-55.16H225.22c-15.24 0-27.59-12.35-27.59-27.59V508.58l7.45-3.31c21.27-6 43.76-6.22 65.14-.64 21.39 5.58 40.9 16.75 56.53 32.36L429.1 639.35l27.59 27.59h1.37l4.14 4.13a47.162 47.162 0 0031.18 11.31 49.967 49.967 0 0030.35-10.48l4.97-4.96 33.66-33.66c6.31-6 13.62-10.71 21.52-14.01v-57.88a123.238 123.238 0 00-59.86 33.27l-30.35 30.35-25.11-25.38-102.63-101.81c-44.2-43.7-107.82-61.54-168.3-47.18V225.51c0-15.23 12.35-27.59 27.59-27.59h551.79c7.32-.01 14.34 2.9 19.51 8.07s8.08 12.19 8.08 19.51v303.48h55.17V225.5c0-21.95-8.72-43-24.24-58.53A82.8 82.8 0 00777 142.73H225.22z"></path>
      <path d="M533.44 313c-20.69 20.7-32.32 48.78-32.32 78.04s11.63 57.34 32.32 78.04c20.7 20.69 48.77 32.32 78.04 32.32s57.34-11.63 78.04-32.32c20.69-20.7 32.32-48.77 32.32-78.04S710.21 333.7 689.52 313c-20.7-20.69-48.77-32.32-78.04-32.32s-57.34 11.63-78.04 32.32zm133.22 78.04c0 14.63-5.81 28.67-16.16 39.02a55.186 55.186 0 01-78.04 0 55.186 55.186 0 010-78.04 55.186 55.186 0 0178.04 0 55.186 55.186 0 0116.16 39.02z"></path>
    </svg>
  );
}

export default Icon;
