import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  gap: 1em;
  z-index: 3;
`;
const NumButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  width: 1em;
  height: 1em;
  border-radius: 1.5em;
  border: none;
  font-size: 2em;
  padding: 0px;
  padding-bottom: 0.08em;
  background: var(--pink);
  color: var(--silver);
  font-weight: 600;
  text-align: center;
`;
const Num = styled.span`
  font-size: 3em;
  padding-bottom: 0.05em;
  min-width: 2ch;
  font-variant: numeric;
  text-align: center;
`;

interface QuantityProps {
  value: number;
  onChange: (value: number) => void;
  interval: number;
  min: number;
  max: number;
}

export const Quantity = ({
  value,
  onChange,
  interval,
  min,
  max,
}: QuantityProps) => {
  const increment = () => {
    if (value < max) {
      onChange(value + interval);
    }
  };

  const decrement = () => {
    if (value > min) {
      onChange(value - interval);
    }
  };

  return (
    <Wrapper>
      <QuantityDisplay value={value} interval={interval} max={max} />
      <Controls>
        <NumButton onClick={decrement}>-</NumButton>
        <Num>{value}</Num>
        <NumButton onClick={increment}>+</NumButton>
      </Controls>
    </Wrapper>
  );
};

const DisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 35vh;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    circle {
      fill: var(--yellow);
    }
  }

  @media (min-width: 600px) {
    height: 30vh;
  }
`;
const DisplayInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 200ms;
  width: 100%;
`;

const QuantityDisplay = ({
  value,
  interval,
  max,
}: {
  value: number;
  interval: number;
  max: number;
}) => {
  const stageRef = React.useRef<HTMLDivElement>(null);
  const [stage, setStage] = React.useState({ width: 1, height: 1 });

  React.useLayoutEffect(() => {
    const measure = () => {
      const rect = stageRef.current?.getBoundingClientRect();
      if (rect) {
        setStage({ width: rect.width, height: rect.height });
      }
    };
    window.addEventListener("resize", measure);
    measure();

    return () => {
      window.removeEventListener("resize", measure);
    };
  }, []);

  const numRows = value / interval;
  const totalRows = max / interval;
  const numRowsValue = useMotionValue(numRows);
  const intervalValue = useMotionValue(interval);

  React.useEffect(() => {
    numRowsValue.set(value / interval);
  }, [numRowsValue, interval, value]);

  const aspectRatio = stage.height / stage.width;
  const stageWidth = 100;
  const stageHeight = stageWidth * aspectRatio;
  const gap = stageWidth * 0.015;
  const radius = Math.min(
    stageWidth / interval - gap,
    stageHeight / numRows - gap
  );

  const transX = useTransform(
    intervalValue,
    (i) => (stageWidth - (radius + gap) * i) / 2
  );
  const transY = useTransform(
    numRowsValue,
    (k) => (stageHeight - (radius + gap) * k) / 2
  );
  const transXSpring = useSpring(transX, {
    stiffness: 1000,
    damping: 200,
  });
  const transYSpring = useSpring(transY, {
    stiffness: 1000,
    damping: 200,
  });

  React.useEffect(() => {}, [radius]);

  return (
    <DisplayWrapper ref={stageRef}>
      <DisplayInnerWrapper>
        <svg
          viewBox={`0 0 ${stageWidth} ${stageHeight}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.g
            style={{
              translateX: transXSpring,
              translateY: transYSpring,
            }}
          >
            {new Array(totalRows)
              .fill(null)
              .map((_, i) =>
                new Array(interval)
                  .fill(null)
                  .map((_, k) => (
                    <Circle
                      rowIndex={i}
                      columnIndex={k}
                      radius={radius}
                      opacity={i < numRows ? 1 : 0}
                      gap={gap}
                      key={k}
                    />
                  ))
              )}
          </motion.g>
        </svg>
      </DisplayInnerWrapper>
    </DisplayWrapper>
  );
};

interface CircleProps {
  rowIndex: number;
  columnIndex: number;
  radius: number;
  gap: number;
  opacity: number;
}

const Circle = ({
  rowIndex,
  columnIndex,
  radius,
  gap,
  opacity,
}: CircleProps) => {
  const radiusValue = useMotionValue(radius);
  const radiusSpring = useSpring(radiusValue, {
    stiffness: 1000,
    damping: 200,
  });
  const xValue = useTransform(
    radiusSpring,
    (r) => r / 2 + r * columnIndex + gap * columnIndex
  );
  const yValue = useTransform(
    radiusSpring,
    (r) => r / 2 + r * rowIndex + gap * rowIndex
  );
  const radiusFinal = useTransform(radiusSpring, (r) => r / 2);
  const opacityValue = useMotionValue(opacity);
  const opacitySpring = useSpring(opacityValue, {
    stiffness: 1500,
    damping: 200,
  });

  React.useEffect(() => {
    radiusValue.set(radius);
  }, [radius, radiusValue]);

  React.useEffect(() => {
    opacityValue.set(opacity);
  }, [opacity, opacityValue]);

  return (
    <motion.circle
      style={{ opacity: opacitySpring }}
      cx={xValue}
      cy={yValue}
      r={radiusFinal}
    />
  );
};
