import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import React from "react";
import styled from "styled-components";
import ballCakeImg from "../img/ball_cake.jpg";
import blueCakeImg from "../img/blue_cake.jpg";
import pinkCakeImg from "../img/pink_cake.jpg";
import sprinkleCookieImg from "../img/sprinkle_cookie.jpg";
import sugarCookieImg from "../img/sugar_cookie.jpg";
import yellowCakeImg from "../img/yellow_cake.jpg";

type ThemeColor = "mint" | "yellow" | "pink" | "blue" | "silver" | "red";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7vw;
  gap: 30px;
  padding-bottom: 20vh;
  width: 100%;
  max-width: 500px;

  @media (min-width: 600px) {
    padding: 1vw;
    padding-bottom: 20vh;
  }
`;
const ImageWrapper = styled.div`
  position: relative;
  width: 80%;
`;
const Image = styled.img`
  border-radius: 15px;
  z-index: 2;
  width: 100%;
  max-height: 100%;
  box-shadow: 6vh 6vh 0 rgba(0, 0, 0, 0.05);
`;
const SprinkleDiv = styled(motion.div)`
  width: min(30%, 200px);
  height: min(12vw, 50px);
  max-height: 80px;
  border-radius: 6vw;
  position: absolute;
  z-index: -1;
  box-shadow: 1vh 1vh 0 rgba(0, 0, 0, 0.2);
`;

interface GalleryImageProps {
  src: string;
  left?: boolean;
  children?: React.ReactNode;
}

interface SprinkleProps {
  color?: ThemeColor;
  left?: string;
  top?: string;
  bottom?: string;
  right?: string;
  angle?: number;
  width?: string;
  depth?: 1 | 2 | 3 | 4;
  clockwise?: boolean;
}

const GalleryImage = ({ src, children, left }: GalleryImageProps) => {
  const [hasLoaded, setHasLoaded] = React.useState(false);

  const handleLoaded = () => setHasLoaded(true);

  return (
    <ImageWrapper
      style={{ alignSelf: left ? "flex-start" : "flex-end" }}
      onLoad={handleLoaded}
    >
      <Image src={src} />
      {hasLoaded && children}
    </ImageWrapper>
  );
};

const Sprinkle = ({
  color = "pink",
  left,
  top,
  bottom,
  right,
  angle = 0,
  clockwise,
  width,
  depth = 1,
}: SprinkleProps) => {
  const divRef = React.useRef(null);
  const { scrollYProgress } = useScroll({ target: divRef });
  const ySpring = useSpring(scrollYProgress, { stiffness: 1000, damping: 100 });
  const translateY = useTransform(
    ySpring,
    (y) => `${(y - 0.5) * depth * 20}vh`
  );
  const rotate = useTransform(
    ySpring,
    (y) => angle + (clockwise ? -1 : 1) * ((y - 0.5) * 70)
  );

  return (
    <SprinkleDiv
      ref={divRef}
      style={{
        width: `min(${width}, 300px)`,
        background: `var(--${color})`,
        left,
        top,
        bottom,
        right,
        rotate,
        zIndex: depth === 1 ? -1 : depth,
        boxShadow: `${depth * 2}vh ${depth * 2}vh 0 rgba(0, 0, 0, 0.075)`,
        translateY,
      }}
    />
  );
};

export const ImageGallery = () => (
  <Wrapper>
    <GalleryImage src={pinkCakeImg}>
      <Sprinkle
        color="yellow"
        width="60%"
        left="-30%"
        top="15%"
        depth={2}
        angle={-34}
      />
      <Sprinkle
        color="mint"
        width="50%"
        left="-30%"
        top="75%"
        depth={1}
        angle={16}
      />
      <Sprinkle
        color="silver"
        width="60%"
        right="8%"
        bottom="-20%"
        depth={3}
        angle={37}
        clockwise
      />
    </GalleryImage>
    <GalleryImage src={yellowCakeImg} left>
      <Sprinkle
        color="red"
        left="-8%"
        bottom="-2%"
        width="45%"
        angle={24}
        clockwise
      />
      <Sprinkle
        color="pink"
        right="-28%"
        bottom="30%"
        angle={-30}
        width="60%"
        depth={2}
      />
    </GalleryImage>
    <GalleryImage src={sprinkleCookieImg}>
      <Sprinkle
        color="yellow"
        depth={2}
        left="-30%"
        top="30%"
        angle={57}
        width="60%"
        clockwise
      />
      <Sprinkle
        color="mint"
        depth={2}
        right="35%"
        bottom="-20%"
        angle={19}
        width="55%"
        clockwise
      />
    </GalleryImage>
    <GalleryImage src={ballCakeImg} left>
      <Sprinkle
        color="pink"
        width="65%"
        left="10%"
        bottom="34%"
        angle={67}
        depth={3}
      />
      <Sprinkle
        color="red"
        width="45%"
        right="-30%"
        top="34%"
        angle={-77}
        depth={1}
        clockwise
      />
    </GalleryImage>
    <GalleryImage src={sugarCookieImg}>
      <Sprinkle
        color="yellow"
        width="54%"
        left="-22%"
        top="16%"
        depth={2}
        angle={-23}
      />
      <Sprinkle
        color="silver"
        clockwise
        depth={3}
        bottom="-10%"
        width="45%"
        angle={69}
      />
    </GalleryImage>
    <GalleryImage src={blueCakeImg} left>
      <Sprinkle color="red" bottom="5%" right="-20%" width="60%" />
      <Sprinkle
        color="mint"
        bottom="50%"
        right="-20%"
        width="50%"
        angle={-40}
        depth={2}
      />
    </GalleryImage>
  </Wrapper>
);
