import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getSanityImage } from "../../api";
import { FormButton } from "../../components/FormButton";
import CartAdd from "../../img/CartAdd";
import { useOrder, useProductSchemas } from "./OrderForm";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 1em;
  gap: 1em;
  padding-bottom: 6em;
`;
const AddProductButton = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  border: 2px dotted var(--silver);
  background: none;
  padding: 0.35em;
  border-radius: 0.65em;
  color: var(--silver);
  text-decoration: none;
`;
const AddProductText = styled.span`
  @media (min-width: 600px) {
    font-size: 1.25em;
  }
`;
const Thumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  width: 3em;
  height: 3em;
  border-radius: 0.3em;
  background-size: cover;
  background-origin: center center;
  flex-shrink: 0;
  fill: var(--silver);

  svg {
    width: 100%;
    max-height: 3em;
  }

  @media (min-width: 600px) {
    width: 4em;
    height: 4em;
  }
`;
const ProductTile = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5em;
  background: var(--silver);
  color: var(--blue);
  padding: 0.35em;
  border-radius: 0.65em;
  overflow: hidden;
  box-shadow: 0.5em 0.5em rgba(0, 0, 0, 0.2);
  text-decoration: none;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const ProductName = styled.span`
  font-weight: 600;

  @media (min-width: 600px) {
    font-size: 1.25em;
  }
`;
const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8em;

  @media (min-width: 600px) {
    font-size: 1em;
  }
`;
const ProductControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  margin-left: auto;
  gap: 0.5em;
`;
const ControlButton = styled.button`
  padding: 0px;
  padding-bottom: 0.1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: var(--blue);
  border-radius: 100%;
  width: 1em;
  height: 1em;
  font-size: 1.5em;
  line-height: 0;
  box-shadow: 0.15em 0.15em 0px rgba(0, 0, 0, 0.2);
  fill: var(--silver);
`;
const Controls = styled.div`
  flex-direction: column;
  justify-content: center;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  padding: 1em;
  z-index: 9;
  width: 100%;

  &::before {
    content: "";
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    height: calc(100% + 5em);
    background: linear-gradient(
      to top,
      var(--blue) 0%,
      var(--blue) 60%,
      var(--blue-transparent)
    );
    pointer-events: none;
    z-index: -1;
  }

  @media (min-width: 600px) {
    position: relative;
    justify-content: center;
    padding: 0px;
    padding-top: 2em;

    &::before {
      display: none;
    }
  }
`;
const ControlColumn = styled.div`
  gap: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 570px;

  @media (min-width: 600px) {
    flex-direction: column-reverse;
  }
`;

export const ProductsCart = () => {
  const { order, deleteProduct } = useOrder();
  const { products } = useProductSchemas();
  const navigate = useNavigate();

  if (!products) return null;

  const handleSubmit = () => {
    navigate("/order/delivery");
  };

  return (
    <Wrapper>
      {!!order.products.length &&
        order.products.map((product) => {
          const productSchema = products[product.productId];

          const selectedFlavor = product.value.flavors?.selected;
          const customFlavor = product.value.flavors?.customFlavor;
          const namePrefix = customFlavor
            ? "Custom"
            : selectedFlavor
            ? selectedFlavor
            : "";
          const productName = `${namePrefix}${namePrefix ? " " : ""}${
            productSchema.name
          }`;

          return (
            <ProductTile
              to={`/order/products/edit/${productSchema._id}/${product.id}/${productSchema.orderFields[0]._key}`}
              key={product.id}
            >
              <Thumbnail
                style={{
                  backgroundImage: `url(${getSanityImage(
                    productSchema.photo.asset
                  )
                    .width(200)
                    .height(200)
                    .crop("center")
                    .fit("crop")
                    .auto("format")
                    .url()})`,
                }}
              />
              <Column>
                <ProductName>{productName}</ProductName>
                <ProductDetails>
                  {productSchema.orderFields.map((step) => {
                    if (step._type === "cakeSize") {
                      const height = step.heights.find(
                        (x) => x._key === product.value.cakeSize?.heightId
                      );
                      const diameter = height?.diameters.find(
                        (x) => x._key === product.value.cakeSize?.diameterId
                      );
                      const inches = diameter?.inches.split(", ") ?? [];

                      if (!height) return "Height not selected";

                      return (
                        <span>
                          {height?.name}
                          {": "}
                          {inches?.map((inc, i) => (
                            <span>
                              {inc}
                              {i === inches.length - 1 ? "in" : "in, "}
                            </span>
                          ))}
                        </span>
                      );
                    }

                    if (step._type === "quantity") {
                      return (
                        <span>
                          Quantity: {product.value.quantity || "Not Selected"}
                        </span>
                      );
                    }

                    return null;
                  })}
                </ProductDetails>
              </Column>
              <ProductControls>
                <ControlButton
                  onClick={() => {
                    deleteProduct(product);
                  }}
                >
                  ×
                </ControlButton>
              </ProductControls>
            </ProductTile>
          );
        })}
      <AddProductButton to="add">
        <Thumbnail>
          <CartAdd />
        </Thumbnail>
        <AddProductText>
          Add {order.products.length > 0 ? "Another" : "Your First"} Treat
        </AddProductText>
      </AddProductButton>
      {order.products.length > 0 && (
        <Controls>
          <ControlColumn>
            <FormButton style={{ marginTop: 0 }} onClick={handleSubmit}>
              Next Step
            </FormButton>
          </ControlColumn>
        </Controls>
      )}
    </Wrapper>
  );
};
