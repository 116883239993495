import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  width: 100vw;
  position: fixed;
  left: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5vh;
  z-index: 99;

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 130%;
    background: linear-gradient(to top, var(--blue), var(--blue-transparent));
    z-index: -1;
  }
`;
const colorRotate = keyframes`
  0%{
    border: max(0.8vh, 8px) solid var(--yellow);
    background: var(--pink);
    color: var(--blue);
  }
  24.9%{
    border: max(0.8vh, 8px) solid var(--yellow);
    background: var(--pink);
    color: var(--blue);
  }
  25%{
    border: max(0.8vh, 8px) solid var(--pink);
    background: var(--mint);
    color: var(--red);
  }
  49.9%{
    border: max(0.8vh, 8px) solid var(--pink);
    background: var(--mint);
    color: var(--red);
  }
  50%{
    border: max(0.8vh, 8px) solid var(--mint);
    background: var(--yellow);
    color: var(--blue);
  }
  74.9%{
    border: max(0.8vh, 8px) solid var(--mint);
    background: var(--yellow);
    color: var(--blue);
  }
  75%{
    border: max(0.8vh, 8px) solid var(--pink);
    background: var(--red);
    color: var(--yellow);
  }
  99.9%{
    border: max(0.8vh, 8px) solid var(--pink);
    background: var(--red);
    color: var(--yellow);
  }
  100%{
    border: max(0.8vh, 8px) solid var(--blue);
    background: var(--pink);
    color: var(--blue);
  }
`;
const Button = styled(Link)`
  border-radius: 20vw;
  font-size: max(4vh, 36px);
  font-family: var(--font-primary);
  pointer-events: all;
  border: none;
  line-height: 1;
  padding: max(2vh, 20px) max(5vh, 30px);
  padding-top: max(1.5vh, 15px);
  box-shadow: 1vh 1vh 0 rgba(0, 0, 0, 0.2);
  animation: ${colorRotate} 5s linear infinite;
  text-decoration: none;
`;

export const FloatingCTAButton = ({
  children,
  href,
}: {
  children: React.ReactNode;
  href: string;
}) => {
  return (
    <Wrapper>
      <Button to={href}>{children}</Button>
    </Wrapper>
  );
};
