import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  align-items: flex-start;
  padding: 0.25em;
`;
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;
const Border = styled.div`
  pointer-events: none;
  position: absolute;
  left: -0.25em;
  top: -0.25em;
  width: calc(100% + 0.5em);
  height: calc(100% + 0.5em);
  border-radius: 0.5em;
  border: 2px solid rgba(255, 255, 255, 0.4);
`;
const Input = styled.input`
  padding: 0.5em 0.5em;
  padding-bottom: 0.55em;
  font-size: 1em;
  border-radius: 0.25em;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  width: 100%;
  height: 2em;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  text-align: left;

  &:focus {
    outline: none;
    background: var(--focus-background);
  }

  &:focus + ${Border} {
    border-color: var(--mint);
  }

  &[aria-invalid="true"] {
    background: var(--error-background);
  }

  &[aria-invalid="true"] + ${Border} {
    border-color: var(--error-color);
  }

  color-scheme: dark;
`;
const Label = styled.label`
  font-size: 0.8em;
  font-weight: 600;
  margin-bottom: 0.5em;
  margin-left: -0.25em;
`;
const Description = styled.p`
  font-size: 0.5em;
  margin-top: -0.5em;
  margin-left: -0.25em;
`;
const ErrorText = styled.p`
  font-size: 0.65em;
  margin: 0px;
  margin-top: 0.65em;
  margin-bottom: -0.65em;
  margin-left: -0.25em;
  font-style: italic;
  color: var(--error-color);
`;

type TextInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "label" | "value" | "onChange" | "error"
> & {
  label: string;
  description?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
};

export const TextInput = ({
  label,
  description,
  value,
  onChange,
  error,
  ...props
}: TextInputProps) => {
  const id = React.useId();

  const handleChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <Wrapper>
      <Label htmlFor={`input_${id}`}>{label}</Label>
      {description && <Description>{description}</Description>}
      <InputWrapper>
        <Input
          type="text"
          {...props}
          id={`input_${id}`}
          onChange={handleChange}
          value={value}
          aria-invalid={!!error}
        />
        <Border />
      </InputWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  );
};
