import styled from "styled-components";

export const FormButton = styled.button`
  width: 100%;
  padding: 0.75em 2em;
  font-size: 24px;
  background: var(--mint);
  border: none;
  border-radius: 0.5em;
  color: var(--background-color);
  font-weight: 800;
  margin-top: 40px;
  cursor: pointer;

  @media (min-width: 500px) {
    max-width: 300px;
  }

  &:disabled {
    opacity: 0;
  }
`;
