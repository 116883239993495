import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getSanityImage } from "../../api";
import * as Schema from "../../schema";
import { useProductSchemas } from "./OrderForm";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ButtonWrapper = styled.button`
  border: none;
  background: var(--silver);
  color: var(--background-color);
  font-size: 18px;
  font-weight: 600;
  border-radius: 0.5em;
  padding: 0px;
  overflow: hidden;
  box-shadow: 0.5em 0.6em 0px rgba(0, 0, 0, 0.1);
  transition: transform 100ms;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }
`;
const ProductTitle = styled.span`
  display: block;
  line-height: 1;
  padding-bottom: 0.5em;
  padding-top: 0.25em;
`;
const Image = styled.img`
  width: 100%;
  -webkit-touch-callout: none !important;
  pointer-events: none;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  gap: 16px;
  max-width: 100%;

  @media (min-width: 600px) {
    ${ButtonWrapper} {
      display: flex;
      align-items: center;
      font-size: 20px;
      gap: 10px;
    }

    ${Image} {
      max-width: 80px;
    }

    ${ProductTitle} {
      padding-bottom: 0px;
    }
  }
`;

interface ProductButtonProps {
  photoSrc: string;
  product: Schema.Product;
}

const ProductButton = ({ product, photoSrc }: ProductButtonProps) => {
  const navigate = useNavigate();

  const startProductAdd = () => {
    navigate(
      `/order/products/add/${product._id}/${product.orderFields[0]._key}`
    );
  };

  return (
    <ButtonWrapper onClick={startProductAdd}>
      <Image src={photoSrc} />
      <ProductTitle>{product.name}</ProductTitle>
    </ButtonWrapper>
  );
};

export const PickProduct = () => {
  const { isLoading, orderedProducts } = useProductSchemas();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <p>What do you want us to make for you?</p>
      <Grid>
        {orderedProducts.map((productSchema) => (
          <ProductButton
            product={productSchema}
            photoSrc={getSanityImage(productSchema.photo.asset)
              .width(400)
              .height(400)
              .crop("center")
              .fit("crop")
              .auto("format")
              .url()}
            key={productSchema._id}
          />
        ))}
      </Grid>
    </Wrapper>
  );
};
